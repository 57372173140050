import { reactive, defineComponent } from "vue";
import Navbar from "@/presentation/components/navigation/Navigation.vue";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import routePath from "@/commons/RoutePath";
export default defineComponent({
    name: "HelpScreen",
    components: {
        Breadcrumb: Breadcrumb,
        Navbar: Navbar
    },
    setup: function () {
        var state = reactive({
            listBreadcrumbs: [
                {
                    name: "TOP",
                    route: "/"
                },
                {
                    name: "FAQ",
                    route: routePath.HELP
                }
            ],
            listFAQUser: [
                {
                    question: "ランキングとは何ですか？",
                    answer: "\u30E9\u30F3\u30AD\u30F3\u30B0\uFF08\u30B9\u30B3\u30A2\u30FB\u30E1\u30C0\u30EB\uFF09\u306F\u3001\u30B3\u30F3\u30DA\u5165\u8CDE\u5C65\u6B74\u30FB\u9806\u4F4D\u306B\u57FA\u3065\u3044\u3066\u8A08\u7B97\u3055\u308C\u308B\u3082\u306E\u3067\u3001\u53C2\u52A0\u8005\u306E\u7686\u69D8\u306E\u73FE\u6642\u70B9\u3067\u306E\u5B9F\u7E3E\u30FB\u5B9F\u529B\u3092\u793A\u3059\u3082\u306E\u3067\u3059\u3002 \u8A73\u7D30\u306F\u3053\u3061\u3089\u3067\u3054\u78BA\u8A8D\u3044\u305F\u3060\u3051\u307E\u3059\u3002"
                },
                {
                    question: "ランキングとは何ですか？",
                    answer: "\u30E9\u30F3\u30AD\u30F3\u30B0\uFF08\u30B9\u30B3\u30A2\u30FB\u30E1\u30C0\u30EB\uFF09\u306F\u3001\u30B3\u30F3\u30DA\u5165\u8CDE\u5C65\u6B74\u30FB\u9806\u4F4D\u306B\u57FA\u3065\u3044\u3066\u8A08\u7B97\u3055\u308C\u308B\u3082\u306E\u3067\u3001\u53C2\u52A0\u8005\u306E\u7686\u69D8\u306E\u73FE\u6642\u70B9\u3067\u306E\u5B9F\u7E3E\u30FB\u5B9F\u529B\u3092\u793A\u3059\u3082\u306E\u3067\u3059\u3002 \u8A73\u7D30\u306F\u3053\u3061\u3089\u3067\u3054\u78BA\u8A8D\u3044\u305F\u3060\u3051\u307E\u3059\u3002"
                },
                {
                    question: "ランキングとは何ですか？",
                    answer: "\u30E9\u30F3\u30AD\u30F3\u30B0\uFF08\u30B9\u30B3\u30A2\u30FB\u30E1\u30C0\u30EB\uFF09\u306F\u3001\u30B3\u30F3\u30DA\u5165\u8CDE\u5C65\u6B74\u30FB\u9806\u4F4D\u306B\u57FA\u3065\u3044\u3066\u8A08\u7B97\u3055\u308C\u308B\u3082\u306E\u3067\u3001\u53C2\u52A0\u8005\u306E\u7686\u69D8\u306E\u73FE\u6642\u70B9\u3067\u306E\u5B9F\u7E3E\u30FB\u5B9F\u529B\u3092\u793A\u3059\u3082\u306E\u3067\u3059\u3002 \u8A73\u7D30\u306F\u3053\u3061\u3089\u3067\u3054\u78BA\u8A8D\u3044\u305F\u3060\u3051\u307E\u3059\u3002"
                }
            ],
            listFAQOrganization: [
                {
                    question: "ランキングとは何ですか？",
                    answer: "\u30E9\u30F3\u30AD\u30F3\u30B0\uFF08\u30B9\u30B3\u30A2\u30FB\u30E1\u30C0\u30EB\uFF09\u306F\u3001\u30B3\u30F3\u30DA\u5165\u8CDE\u5C65\u6B74\u30FB\u9806\u4F4D\u306B\u57FA\u3065\u3044\u3066\u8A08\u7B97\u3055\u308C\u308B\u3082\u306E\u3067\u3001\u53C2\u52A0\u8005\u306E\u7686\u69D8\u306E\u73FE\u6642\u70B9\u3067\u306E\u5B9F\u7E3E\u30FB\u5B9F\u529B\u3092\u793A\u3059\u3082\u306E\u3067\u3059\u3002 \u8A73\u7D30\u306F\u3053\u3061\u3089\u3067\u3054\u78BA\u8A8D\u3044\u305F\u3060\u3051\u307E\u3059\u3002"
                },
                {
                    question: "ランキングとは何ですか？",
                    answer: "\u30E9\u30F3\u30AD\u30F3\u30B0\uFF08\u30B9\u30B3\u30A2\u30FB\u30E1\u30C0\u30EB\uFF09\u306F\u3001\u30B3\u30F3\u30DA\u5165\u8CDE\u5C65\u6B74\u30FB\u9806\u4F4D\u306B\u57FA\u3065\u3044\u3066\u8A08\u7B97\u3055\u308C\u308B\u3082\u306E\u3067\u3001\u53C2\u52A0\u8005\u306E\u7686\u69D8\u306E\u73FE\u6642\u70B9\u3067\u306E\u5B9F\u7E3E\u30FB\u5B9F\u529B\u3092\u793A\u3059\u3082\u306E\u3067\u3059\u3002 \u8A73\u7D30\u306F\u3053\u3061\u3089\u3067\u3054\u78BA\u8A8D\u3044\u305F\u3060\u3051\u307E\u3059\u3002"
                },
                {
                    question: "ランキングとは何ですか？",
                    answer: "\u30E9\u30F3\u30AD\u30F3\u30B0\uFF08\u30B9\u30B3\u30A2\u30FB\u30E1\u30C0\u30EB\uFF09\u306F\u3001\u30B3\u30F3\u30DA\u5165\u8CDE\u5C65\u6B74\u30FB\u9806\u4F4D\u306B\u57FA\u3065\u3044\u3066\u8A08\u7B97\u3055\u308C\u308B\u3082\u306E\u3067\u3001\u53C2\u52A0\u8005\u306E\u7686\u69D8\u306E\u73FE\u6642\u70B9\u3067\u306E\u5B9F\u7E3E\u30FB\u5B9F\u529B\u3092\u793A\u3059\u3082\u306E\u3067\u3059\u3002 \u8A73\u7D30\u306F\u3053\u3061\u3089\u3067\u3054\u78BA\u8A8D\u3044\u305F\u3060\u3051\u307E\u3059\u3002"
                },
                {
                    question: "ランキングとは何ですか？",
                    answer: "\u30E9\u30F3\u30AD\u30F3\u30B0\uFF08\u30B9\u30B3\u30A2\u30FB\u30E1\u30C0\u30EB\uFF09\u306F\u3001\u30B3\u30F3\u30DA\u5165\u8CDE\u5C65\u6B74\u30FB\u9806\u4F4D\u306B\u57FA\u3065\u3044\u3066\u8A08\u7B97\u3055\u308C\u308B\u3082\u306E\u3067\u3001\u53C2\u52A0\u8005\u306E\u7686\u69D8\u306E\u73FE\u6642\u70B9\u3067\u306E\u5B9F\u7E3E\u30FB\u5B9F\u529B\u3092\u793A\u3059\u3082\u306E\u3067\u3059\u3002 \u8A73\u7D30\u306F\u3053\u3061\u3089\u3067\u3054\u78BA\u8A8D\u3044\u305F\u3060\u3051\u307E\u3059\u3002"
                }
            ]
        });
        return {
            state: state
        };
    }
});
