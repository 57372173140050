<template>
    <div class="help_screen">
        <Navbar />
        <Breadcrumb :breadcrumb="state.listBreadcrumbs"/>
        <div class="container">
            <div class="main-area">
                <div class="content">
                    <img src="@/assets/images/help/FAQ.svg" alt="">
                    <p class="subtitle">
                        よくある質問
                    </p>
                    <div class="faq-area">
                        <p class="title">
                            スペシャリスト向け
                        </p>
                        <ul class="faq_list">
                            <li
                                v-for="faq in state.listFAQUser"
                            >
                               <div class="icon">Q</div>
                               <div class="faq">
                                   <p class="question">
                                       {{ faq.question }}
                                   </p>
                                   <p class="answer">
                                        {{ faq.answer }}
                                   </p>
                               </div>
                            </li>
                        </ul>
                    </div>
                    <div class="faq-area">
                        <p class="title">
                            企業向け
                        </p>
                        <ul class="faq_list">
                            <li
                                v-for="faq in state.listFAQOrganization"
                            >
                               <div class="icon">Q</div>
                               <div class="faq">
                                   <p class="question">
                                       {{ faq.question }}
                                   </p>
                                   <p class="answer">
                                        {{ faq.answer }}
                                   </p>
                               </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/help/Help.ts">
</script>

<style lang="scss"  src="@/presentation/views/help/Help.scss" scoped>
</style>
